<script>
import { ref } from 'vue'
import { http } from '@/http'
import { getMobileSystem, browser } from '@/utils'
import { useRoute } from 'vue-router'
import router from '@/routes'
export default {
  setup() {
    let route = useRoute()
    let appDownloadUrl = ref('')
    let merchantPcAddr = ref('')
    let guideMaskVisible = ref(false)

    if(route.query.status !== 'success') {
      router.replace('/')
    }
    http.post('/?cmd=com.xqxc.api.pigear.biz.platform.MerRegSuccessService.getMerRegSuccess', {
      systemType: getMobileSystem()
    }).then(res => {
      if(res.result) {
        appDownloadUrl.value = res.result.appDownloadUrl
        merchantPcAddr.value = res.result.merchantPcAddr
      }
    })

    function handleOpenAndroidGuide(e) {
      if(browser.versions.weixin) {
        e.preventDefault()
        guideMaskVisible.value = true
      }
      
    }
    

    return {
      appDownloadUrl,
      merchantPcAddr,
      handleOpenAndroidGuide,
      guideMaskVisible
    }

  }
}
</script>
<template>
  <div>
    <div class="page">
      <h3>恭喜您已成功成为猪耳朵商家</h3>
      <div>
        <p>猪耳朵商户PC登录地址</p>
        <p><a  :href="merchantPcAddr">{{decodeURIComponent(merchantPcAddr)}}</a></p>
      </div>
      <div>
        <p>猪耳朵商户APP下载</p>
        <p><a :href="appDownloadUrl" @click="handleOpenAndroidGuide" >{{decodeURIComponent(appDownloadUrl)}}</a></p>
      </div>
    
    </div>
    <div v-show="guideMaskVisible" style="text-align: center; z-index: 1000; padding-left: .4rem; box-sizing: border-box;position:fixed; top:0; right:0; bottom:0; left:0; background:#000; opacity:.7">
      <img src="@/assets/images/android-guide.png" />
    </div>
  </div>
</template>
<style scoped>
  .page { padding: .5rem; text-align: center;}
  .page h3 { margin: .5rem; font-size:.4rem }
  .page div { margin:.7rem 0 }
  .page  p { margin-bottom:.3rem; word-break: break-word}
  a { color:#00ABEB }
</style>